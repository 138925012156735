import React from "react"

import {FirestoreCollection} from "react-firestore";

import {withState} from 'recompose';

const StudentView = (props) => (
  <div className="container">
    <div className="row">
      <div className="col mt-3">
        <h3>Student List</h3>
      </div>
    </div>
    <div className="row">
      {props.data.map((student) => (
        <div key={student.id} className="col-6 col-sm-6 col-md-4 col-lg-3 mb-3">
          <div className="card">
            <img className="card-img-top" src={student.image || "/liff/user_default_icon.jpg"} width={286}
                 height={150} style={{objectFit: "cover", objectPosition: "50% 20%"}} alt="Student"/>
            <div className="card-body">
              <span style={{fontWeight: "bold"}}>{student.code}</span>
              <br />
              {student.name}
              <br/>
              <button className="mt-2 mr-2 btn btn-sm btn-primary"
                      onClick={() => props.setQR(props.qr === "student-" + student.id ? "" : "student-" + student.id)}>Show Student QR
              </button>
              <button className="mt-2 btn btn-sm btn-primary"
                      onClick={() => props.setQR(props.qr === "parent-" + student.id ? "" : "parent-" + student.id)}>Show Parent QR
              </button>
              <button className="mt-2 btn btn-sm btn-primary"
                      onClick={() => props.setQR(props.qr === "teacher-" + student.id ? "" : "teacher-" + student.id)}>Show Teacher QR
              </button>
              {props.qr === "student-" + student.id ? (
                <div className="mt-3">
                  <img src={"/LineBotQR?student=" + student.id + "&redirect=1"} style={{width: "100%"}} alt="Student Link QR"/>
                </div>
              ) : null}
              {props.qr === "parent-" + student.id ? (
                <div className="mt-3">
                  <img src={"/LineBotQR?student=" + student.id + "&role=parent&redirect=1"} style={{width: "100%"}} alt="Parent Link QR"/>
                </div>
              ) : null}
              {props.qr === "teacher-" + student.id ? (
                <div className="mt-3">
                  <img src={"/LineBotQR?student=" + student.id + "&role=teacher&redirect=1"} style={{width: "100%"}} alt="Teacher Link QR"/>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const StatedStudentView = withState('qr', 'setQR', "")(StudentView);

const StudentApp = (props) => (
  <FirestoreCollection
    path="students"
    render={({isLoading, data}) => {
      if (isLoading) return (
        <div className="text-center m-3"><h3>Loading Data ...</h3></div>
      );
      if (data.length === 0) return (
        <div><h3>Error: No data found</h3></div>
      );
      return <StatedStudentView data={data}/>
    }}
  />
);

export default StudentApp;
