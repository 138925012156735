import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import firebase from '@firebase/app';
import '@firebase/firestore';
import {FirestoreProvider} from 'react-firestore';

const config = {
  apiKey: 'AIzaSyCeYgN1uZr4H_ruSzWng4CC72O0LGzHAS0',
  projectId: 'taamkrubacon',
};

firebase.initializeApp(config);

const firestore = firebase.firestore();
const settings = {timestampsInSnapshots: true};
firestore.settings(settings);

ReactDOM.render((
  <FirestoreProvider firebase={firebase}>
    <App/>
  </FirestoreProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
