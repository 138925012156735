import React, {Fragment} from "react";

import {FirestoreCollection} from "react-firestore";

import MainShowScan from "./component/MainShowScan";

class ShowScan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {"imagesLoaded": false};
    // this.preload = this.preload.bind(this);
    this.checkImage = this.checkImage.bind(this);
  }

  checkImage = (path) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(path);
      img.onerror = () => reject(path);
      img.src = path;
    });

  render() {
    return (
      <Fragment>
        <FirestoreCollection
          path="students"
          render={({isLoading, data}) => {
            if (data) {
              data.forEach(
                (std) => {
                  this.checkImage(std.image);
                },
              );
            }
            return (
              <Fragment>
                <MainShowScan/>
              </Fragment>
            );
          }}
        />
      </Fragment>
    );
  }
}

export default ShowScan;
