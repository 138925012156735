import React from 'react';
import {BrowserRouter as Router, Route, Link, NavLink, Redirect} from "react-router-dom";

import './App.css';

import MapApp, {StudentMapApp} from './components/map';
import StudentApp from './components/student';
import ShowScanApp from './components/showScan';

const AppLayout = (props) => {
  return (
    <div>
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <Link to="/" className="navbar-brand">Taamkru Bacon</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"/>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink to="/map" className="nav-link" activeClassName="active">Map</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/student" className="nav-link" activeClassName="active">Student</NavLink>
            </li>

          </ul>
        </div>
      </nav>
      {props.children}
    </div>
  );
};


const AppRoute = ({component, ...routeProps}) => {
  return (
    <Route {...routeProps} render={(props) => {
      return (
        <AppLayout {...props} {...routeProps}>
          {React.createElement(component, props)}
        </AppLayout>
      );
    }}/>
  );
};

const App = () => (
  <Router>
    <div>
      <Route exact path="/" render={() => (
        <Redirect to="/map"/>
      )}/>
      <AppRoute exact path="/map" component={MapApp}/>
      <AppRoute exact path="/student" component={StudentApp}/>
      <Route exact path="/map_student" component={StudentMapApp}/>
      <Route exact path="/show_scan" component={ShowScanApp}/>
    </div>
  </Router>
);

export default App;
