import React, {Fragment} from "react";
import styled from "styled-components";
import {drop, dropRight, concat, map} from "lodash";

import {FirestoreCollection} from "react-firestore";

const BG = require("../image/Website-01-BG-new.png");
const Icon = require("../image/icon.png");
const Holder = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-image: url(${BG});
  background-position: 50% 0%;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "TH Sarabun New";
`;

const HolderTotalJoin = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const HolderNumber = styled.div`
  width: 160px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 30px;
  margin-right: 40px;
  margin-top: 50px;
`;

const IconNumber = styled.img`
  width: 35px;
  height: 26px;
`;

const TextNumber = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: #000;
  padding-right: 20px;
`;

const HolderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HolderCardList = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  top: 25%;
  left: 50%;
  margin-left: -540px;
`;

const HolderCard = styled.div`
  width: 240px;
  height: 350px;
  background-color: #fff;
  border: 1px solid #b2bab4;
  border-radius: 5px;
  margin: 0px 8px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
`;

const CardImage = styled.div`
  width: 230px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-end;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0 0;
`;

const CardID = styled.div`
  width: 100%;
  height: 70px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  opacity: 0.5;
`;

const TextID = styled.div`
  font-size: 25px;
  color: #fff799;
  position: absolute;
  left: 25%;
  right: 25%;
  bottom: 10px;
  margin: auto;
`;

const TextCenter = styled.div`
  font-size: 30px;
  font-family: tahoma;
  color: #fff799;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardName = styled.div`
  width: 100%;
  color: #000;
  font-size: 30px;
  font-weight: bold;
  height: 90px;
  display: flex;
  justify-content: center;
  // align-items: center;
  // text-overflow: ellipsis;
  // text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  line-height: 80px;
`;

const Card = (props) => {
  const {id, name, img} = props;
  // console.log(img);
  return (
    <HolderCard>
      <CardImage>
        {<Image src={img}/>}
        <CardID/>
        <TextID>
          <TextCenter>
            {id}
          </TextCenter>
        </TextID>
      </CardImage>
      <CardName>
        {`${name}`}
      </CardName>
    </HolderCard>
  );
};

let lastLastID = 0;
let cardPadding = 0;

const MainShowScan = (props) => {
  return (
    <Fragment>
      <FirestoreCollection
        path="students"
        filter={['checkin_timestamp', '>', 0]}
        sort={'checkin_timestamp:desc'}
        render={({isLoading, data}) => {
          if (isLoading) return (
            <div className="text-center m-3"><h3>Loading Data ...</h3></div>
          );
          // if (data.length === 0) return (
          //   <div><h3>Error: No data found</h3></div>
          // );

          let count = 0;

          // data is list of check in
          // newest checkin is in the back of array (reverse) or front (no reverse)
          if (data) {
            count = data.length;
            let dataList = (data.map((x) => ({
              "id": x.code,
              "name": x.name,
              "img": x.image,
            })));
            // dataList = [].concat(
            //   dataList.map(x => Object.assign({}, x, {id: x.id + "x"})),
            //   dataList.map(x => Object.assign({}, x, {id: x.id + "y"})),
            //   dataList.map(x => Object.assign({}, x, {id: x.id + "z"})),
            // );
            dataList = dataList.slice(-8);
            // console.log("dl ", dataList.map((x) => x.id).join(","));
            if (lastLastID !== 0) {
              if (dataList.length === 8) {
                const newSinceLastTime = 7 - dataList.map((x) => x.id).indexOf(lastLastID);
                // console.log("nslt ", newSinceLastTime);
                cardPadding = (cardPadding + newSinceLastTime) % 8;
                // console.log("cp ", cardPadding);
              }
            }
            if (dataList.length === 8) {
              lastLastID = dataList[dataList.length - 1].id;
            }
            const newDataList = concat(drop(dataList, 8 - cardPadding), dropRight(dataList, cardPadding));
            // console.log("ndl ", newDataList.map((x) => x.id).join(","));
            return (
              <Holder>
                <HolderTotalJoin>
                  <HolderNumber>
                    <IconNumber src={Icon}/>
                    <TextNumber>
                      {count}
                    </TextNumber>
                  </HolderNumber>
                </HolderTotalJoin>
                <HolderContainer>
                  <HolderCardList>
                    {
                      map(newDataList, (item, index) => {
                        return (
                          <Card key={item.id} id={item.id} name={item.name} img={item.img}/>
                        );
                      })
                    }
                  </HolderCardList>
                </HolderContainer>
              </Holder>
            );
          }
        }}
      />
    </Fragment>
  );
};

export default MainShowScan;
