import React from "react"
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"
import Transition from "react-prop-transition";
import {GroundOverlay} from "react-google-maps";

import {FirestoreCollection, FirestoreDocument} from "react-firestore";

const MyMarker = (props) => (
  props.image ? (
    <Marker
      key={props.id}
      position={{lat: props.lat, lng: props.lng}}
      icon={{
        url: props.image,
        scaledSize: {
          width: 50,
          height: 50
        }
      }}
      label={props.student_name}
    />
  ) : (
    <Marker
      key={props.id}
      position={{lat: props.lat, lng: props.lng}}
      label={props.student_name}
    />
  )
);

const map_style = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#212121",
      },
    ],
  },
  {
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575",
      },
    ],
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121",
      },
    ],
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575",
      },
    ],
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e",
      },
    ],
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd",
      },
    ],
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575",
      },
    ],
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818",
      },
    ],
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161",
      },
    ],
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b",
      },
    ],
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c",
      },
    ],
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a",
      },
    ],
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737",
      },
    ],
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c",
      },
    ],
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e",
      },
    ],
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161",
      },
    ],
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575",
      },
    ],
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000",
      },
    ],
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d",
      },
    ],
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
];

const MyMapComponent = withScriptjs(withGoogleMap((props) => {

  // window.google.maps.event.addListener(map, 'click', function( event ){
  //   alert( "Latitude: "+event.latLng.lat()+" "+", longitude: "+event.latLng.lng() );
  // });
  //

  const markers = props.markers.map(data => (
    <Transition
      key={data.id}
      props={{lat: data.lat, lng: data.lng}}
      duration={400}
      easing="cubic">
      {i_props => (
        <MyMarker {...data} {...i_props}/>
      )}
    </Transition>
  ));

  // const beacons = {
  //   "01185ca83a": [13.74582, 100.54111, "Beacon 3"],
  //   "01185ca629": [13.74591, 100.54111, "Beacon 2"],
  //   "01185c935d": [13.74597, 100.54115, "Beacon 1"],
  //
  //   "000002415b": [13.74586, 100.54099, "Beacon L1"],
  //   "000002415c": [13.74589, 100.54105, "Beacon L2"],
  //   "000002415d": [13.74588, 100.54116, "Beacon L3"],
  // };
  //
  // const markers2 = Object.keys(beacons).map(id => (
  //   <Transition
  //     key={id}
  //     props={{lat: beacons[id][0], lng: beacons[id][1]}}
  //     duration={400}
  //     easing="cubic">
  //     {i_props => (
  //       <MyMarker {...beacons[id]} student_name={beacons[id][2]} {...i_props}/>
  //     )}
  //   </Transition>
  // ));

  return (
    <GoogleMap
      defaultZoom={20}
      defaultCenter={{lat: 13.7460348, lng: 100.5410995}}
      options={{
        "disableDefaultUI": true,
        "styles": map_style,
      }}
    >
      {markers}
      {/*{markers2}*/}
      <GroundOverlay
        defaultUrl={"https://firebasestorage.googleapis.com/v0/b/taamkrubacon.appspot.com/o/41913864_2192813480759781_1856137861485559808_n.png?alt=media&token=345c4eba-9d9e-482d-a239-0dd965347d1f"}
        defaultBounds={
          {
            "north": 13.746383, //100.540727
            "east": 100.541446,//new window.google.maps.LatLng(13.746174, 100.541223),
            "south": 13.745669, //100.541159),
            "west": 100.540441/*new window.google.maps.LatLng(13.745876, 100.540665),*/
          }
        }
        defaultOpacity={1}
      />
    </GoogleMap>
  );
}));

const MapView = (props) => (
  <MyMapComponent
    isMarkerShown
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDZfY_gv3qWM0WHuUPSPn5TKpgYaHnO79M&v=3.exp&libraries=geometry,drawing,places"
    loadingElement={<div style={{height: `100%`}}/>}
    containerElement={<div style={{
      height: `100vh`,
      position: "absolute",
      top: "0",
      left: "0",
      width: "100vw",
      zIndex: "-1"
    }}/>}
    mapElement={<div style={{height: `100%`}}/>}
    {...props}
  />
);


const MapApp = (props) => (
  <FirestoreCollection
    path="students"
    render={({isLoading, data}) => {
      if (isLoading) return (
        <div className="text-center m-3"><h3>Loading Data ...</h3></div>
      );
      if (data.length === 0) return (
        <div><h3>Error: No data found</h3></div>
      );
      const coordinates = data.map(i => ({
        id: i.code,
        lat: i.current_location.latitude,
        lng: i.current_location.longitude,
        image: i.image_marker,
        student_name: i.code,
      }));
      return <MapView markers={coordinates}/>
    }}
  />
);

const StudentMapApp = (props) => (
  <FirestoreDocument
    path={"students/" + window.location.search.split("=")[1] || ""}
    render={({isLoading, data}) => {
      if (isLoading) return (
        <div className="text-center m-3"><h3>Loading Data ...</h3></div>
      );
      console.log(window.location.search, data);
      if (!data || data.id === "undefined") return (
        <div><h3>Error: No data found</h3></div>
      );
      const coordinates = [data].map(i => ({
        id: i.code,
        lat: i.current_location.latitude,
        lng: i.current_location.longitude,
        image: i.image_marker,
        student_name: i.code,
      }));
      return (
        <div>
          <nav className="navbar navbar-expand navbar-dark bg-dark">
            <img className={"mr-3"} src={data.image} alt={"Student Avatar"}
                 style={{height: 40, width: 40, borderRadius: 20, objectFit: "cover", objectPosition: "0 0"}}/>
            <span className="navbar-brand mb-0 h1">{data.name}</span>
          </nav>
          <MapView markers={coordinates}/>
        </div>
      );
    }}
  />
);

export default MapApp;

export {StudentMapApp};
